import React, { useState, useEffect } from 'react';

const MainPage: React.FC = () => {
    const [htmlContent, setHtmlContent] = useState<string>('');

    useEffect(() => {
        // Fetch the HTML file content
        fetch('/index.html')
            .then((response) => response.text())
            .then((data) => {
                setHtmlContent(data);

                // Only add the script after the HTML content is set
                if (!document.getElementById('webflow-script')) {
                    const script = document.createElement('script');
                    script.id = 'webflow-script';
                    script.src = '/js/webflow.js';
                    script.async = true;
                    document.body.appendChild(script);

                    // Remove the script on component unmount
                    return () => {
                        document.body.removeChild(script);
                    };
                }
            });

    }, []);

    return (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default MainPage;
