import {
    useEffect,
    useRef,
    useState
} from 'react';
import './Profile.css'
import Axios from 'axios';
import Options from './Options';
import WineList from './WineList';
import Statistics from './Statistics';
import vinelyLogo from "./../../common/logo120.png";
import QRCodeStyling from 'qr-code-styling';
import { IProfileContractV1 } from "../../../../common/src/contracts/v1/IProfileContractV1"
import UserNotFound from './UserNotFound';
import { GetApiUrl } from '../../ServiceEndpoint';


interface ProfileProps {
    username: string | undefined,
}

/**
 * I don't like this, this should be in the user brokers but I can't 
 * figure that out yet.. so, it is here temporarily
 * @param username The username
 * @returns The user profile
 */
async function GetProfileAsync(
    username: string
): Promise<IProfileContractV1 | null> {

    const url = `${GetApiUrl()}/api/v1/profiles/${username}`
    var result = await Axios
        .get(url)
        .catch(e => {
            console.error(e)
            return null
        })

    if (result?.status === 200) {
        return result.data

    } else {
        return null
    }
}

const Profile = (props: ProfileProps) => {

    const qrRef = useRef<HTMLDivElement>(null);
    const [userNotFound, setUserNotFound] = useState(false)
    const [wineList, setWineList] = useState()
    const [profile, setProfile] = useState<IProfileContractV1>()

    useEffect(() => {
        try {
            GetProfileAsync(props.username!)
                .then((profile) => {
                    if (profile == null) {
                        setUserNotFound(true);
                    } else {
                        setProfile(profile);
                    }
                });
        } catch (e) { }

    }, [props.username])

    useEffect(() => {
        let tempList: any = []
        profile?.wineList.forEach((wine, index) => {
            tempList.push(wine)
        })
        setWineList(tempList)

        // Set QR code
        const qr = new QRCodeStyling({
            width: 150,
            height: 150,
            type: 'svg',
            image: vinelyLogo,
            dotsOptions: {
                color: "#333",
                type: "dots"
            },
            backgroundOptions: {
                color: 'transparent'
            },
            data: GetApiUrl() + `/${props.username}`
        });

        qr.append(qrRef.current!)

    }, [props.username, profile])

    if (userNotFound) {
        return (
            <UserNotFound />
        )
    } else if (profile) {
        return (
            <div id="container">
                <div id="profile-cotaniner">

                    <div id="profile-img-container">
                        {
                            profile?.profilePhoto !== '' ||
                                profile?.profilePhoto !== null ||
                                profile?.profilePhoto !== undefined ?
                                <img src={profile?.profilePhoto} alt="User profile" />
                                : null
                        }
                    </div>

                    <div id="subview-container">
                        <div id="qr-container">
                            <div ref={qrRef} />
                        </div>

                        <div id="profile-detail-container">
                            <h2>
                                {props.username}
                            </h2>

                            <div id="joined-date">
                                Joined on {profile?.date}
                            </div>

                            {
                                profile?.description !== '' ?
                                    <div id="description">
                                        {profile?.description}
                                    </div> : null
                            }
                        </div>
                    </div>

                    <Statistics wineList={wineList} />
                </div>

                <div>
                    <Options wineList={wineList} setWineList={setWineList} />
                    <WineList wineList={wineList} />
                </div>

                <span
                    id="join-text-container"
                    onClick={() => {
                        window.open(GetApiUrl())
                    }}>
                    <span id="join-text">
                        Join <b>Vinely</b> to create your Cellar! →
                    </span>
                </span>
            </div>

        )
    } else {
        return null
    }
}

export default Profile