import "./UserNotFound.css"
import UserNotFoundSvg from "./UserNotFoundSvg"

const UserNotFound = () => {
    return (
        <div id="user-not-found">
            <span>
                <UserNotFoundSvg />
                <h1>User Not Found!</h1>
                <p>Make sure the username is correct.</p>
            </span>
        </div>
    )
}

export default UserNotFound