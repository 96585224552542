import {
    useEffect,
    useState
} from 'react';
import "./Statistics.css";
import StarSvg from './StarSvg';
import { WineRecord } from '../../../../common/src/models/WineRecord';

interface StatisticsProps {
    wineList: WineRecord[] | undefined
}

const Statistics = (props: StatisticsProps) => {

    const [average, setAverage] = useState(0)

    useEffect(() => {
        let sum = 0
        props.wineList?.map((wine: WineRecord) => {
            sum += wine.rating ?? 0;
            return wine.rating ?? 0;
        })
        const avg = Math.round((sum / props.wineList?.length!) * 100) / 100
        setAverage(avg)

    }, [props.wineList])

    return (
        <div id="statistics">
            <div id="num-wine">
                <div>
                    {
                        props.wineList?.length
                    }
                </div>
                <div>
                    Wine in Cellar
                </div>
            </div>

            <div id="avg-rating">
                <div id="rating">
                    <StarSvg width={"25px"} />
                    <span>
                        {
                            average
                        }
                    </span>
                </div>
                <div>
                    Average Rating
                </div>
            </div>
        </div>
    )
}

export default Statistics