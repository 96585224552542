import {
    Dispatch,
    SetStateAction,
    useEffect,
    useState
} from "react";
import "./Options.css"
import { WineRecord } from "../../../../common/src/models/WineRecord";

interface OptionsProps {
    wineList: WineRecord[] | undefined,
    setWineList: Dispatch<SetStateAction<any>>
}

const Options = (props: OptionsProps) => {

    const [sortByRatingSelected, setSortByRatingSelected] = useState(true)

    useEffect(() => {
        if (props.wineList !== undefined) {
            // Deep copy original state
            const newWineList: WineRecord[] = JSON.parse(JSON.stringify(props.wineList!))

            if (sortByRatingSelected) {
                // Sort by rating
                newWineList.sort((a: WineRecord, b: WineRecord) =>
                    b.rating! - a.rating!)

            } else {
                // Sort by date
                newWineList.sort((a: WineRecord, b: WineRecord) =>
                    b.addedDate.localeCompare(a.addedDate))

            }

            props.setWineList(newWineList)
        }

    }, [props, props.wineList, sortByRatingSelected])

    return (
        <div>
            <div
                className={`options-button ${sortByRatingSelected ? "selected" : ""}`}
                onClick={() => {
                    setSortByRatingSelected(prev => !prev)
                }}>
                🌟 Best Rating
            </div>

            <div
                className={`options-button ${!sortByRatingSelected ? "selected" : ""}`}
                onClick={() => {
                    setSortByRatingSelected(prev => !prev)
                }}>
                🗓 Most Recent
            </div>
        </div>
    )
}

export default Options