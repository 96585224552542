import React from 'react'
import Profile from "./pages/profile/Profile"
import * as ReactRouter from "react-router-dom"
import PrivacyPolicy from './pages/policies/PrivacyPolicy';
import TermsAndConditions from './pages/policies/TermsAndConditions';
import { GetApiUrl } from './ServiceEndpoint';
import MainPage from './pages/main/Main';

// Declare globally such that index.html can access the function
// @ts-ignore
window.GetApiUrl = GetApiUrl()

export default class App extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    // Required for the front-end routing to work
    const ProfilePageWrapper = () => {
      const { username } = ReactRouter.useParams()
      return <Profile username={username} />
    }

    return (
      <ReactRouter.BrowserRouter>
        <ReactRouter.Routes>
          <ReactRouter.Route path="/" element={<MainPage />} />
          <ReactRouter.Route path="/:username" element={<ProfilePageWrapper />} />
          <ReactRouter.Route path="/pages/privacypolicy" element={<PrivacyPolicy />} />
          <ReactRouter.Route path="/pages/termsandconditions" element={<TermsAndConditions />} />
        </ReactRouter.Routes>
      </ReactRouter.BrowserRouter>
    );
  }
}