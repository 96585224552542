import { SVGProps } from "react"

const UserNotFoundSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={141}
    height={182}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M72.562 53s-4.072-5.429-10.857-5.429C54.919 47.571 50.848 53 50.848 53"
      stroke="#636363"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.41 36c0 19.882-16.117 36-36 36-19.881 0-36-16.118-36-36s16.119-36 36-36c19.883 0 36 16.118 36 36ZM0 144.001c0-32.747 26.547-59.294 59.294-59.294h4.235c32.747 0 59.294 26.547 59.294 59.294H0Z"
      fill="#D9E8F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.41 69.5c18.502 0 33.5-14.999 33.5-33.5 0-18.502-14.998-33.5-33.5-33.5-18.5 0-33.5 14.998-33.5 33.5 0 18.501 15 33.5 33.5 33.5Zm2.12 17.707h-4.236c-30.529 0-55.432 24.088-56.74 54.294h117.715c-1.308-30.206-26.211-54.294-56.74-54.294Zm59.241 54.294c-1.31-31.588-27.332-56.794-59.242-56.794h-4.235c-31.91 0-57.932 25.206-59.242 56.794a60.34 60.34 0 0 0-.052 2.5h122.823c0-.837-.017-1.671-.052-2.5ZM61.411 72c19.882 0 36-16.118 36-36s-16.118-36-36-36-36 16.118-36 36 16.118 36 36 36Z"
      fill="#636363"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.899 50.953C54.903 49.496 57.869 48 61.607 48c3.737 0 6.704 1.496 8.709 2.953 1.004.73 1.78 1.461 2.309 2.012a13.977 13.977 0 0 1 .772.877l.046.058.014.018.007.01a1.251 1.251 0 0 1-.25 1.752 1.248 1.248 0 0 1-1.748-.25l-.002-.001-.002-.003-.007-.01-.018-.022a11.373 11.373 0 0 0-.617-.698 14.896 14.896 0 0 0-1.975-1.72c-1.728-1.258-4.19-2.476-7.238-2.476s-5.51 1.218-7.238 2.475a14.894 14.894 0 0 0-1.974 1.72 11.373 11.373 0 0 0-.618.699l-.025.032-.003.003v.002a1.251 1.251 0 0 1-1.998-1.502v-.002l.002-.003.005-.006.014-.018a6.426 6.426 0 0 1 .209-.258c.14-.168.344-.4.609-.677a17.386 17.386 0 0 1 2.309-2.012Z"
      fill="#636363"
    />
    <path
      d="m96.793 126.494 12.98-7.494 30.137 52.198a5 5 0 0 1-1.831 6.83l-4.319 2.494a5 5 0 0 1-6.83-1.83l-30.137-52.198Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m109.773 119-12.98 7.494 30.137 52.198a5 5 0 0 0 6.83 1.83l4.319-2.494a5 5 0 0 0 1.831-6.83L109.773 119Zm-9.564 8.409 8.649-4.994 14.786 25.609-8.65 4.994-14.785-25.609Zm16.035 27.774 12.851 22.259a2.5 2.5 0 0 0 3.415.915l4.319-2.494a2.5 2.5 0 0 0 .916-3.415l-12.851-22.259-8.65 4.994Z"
      fill="#636363"
    />
    <path
      d="M53.705 110c0-23.748 19.251-43 43-43 23.748 0 43 19.252 43 43s-19.252 43-43 43c-23.749 0-43-19.252-43-43Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.205 110c0 22.368 18.132 40.5 40.5 40.5 22.367 0 40.5-18.132 40.5-40.5s-18.133-40.5-40.5-40.5c-22.368 0-40.5 18.132-40.5 40.5Zm40.5-43c-23.749 0-43 19.252-43 43s19.251 43 43 43c23.748 0 43-19.252 43-43s-19.252-43-43-43Z"
      fill="#636363"
    />
    <path
      d="M64.705 110c0-17.673 14.326-32 32-32 17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32-17.674 0-32-14.327-32-32Z"
      fill="#F0F9FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.205 110c0 16.292 13.207 29.5 29.5 29.5 16.292 0 29.5-13.208 29.5-29.5s-13.208-29.5-29.5-29.5c-16.293 0-29.5 13.208-29.5 29.5Zm29.5-32c-17.674 0-32 14.327-32 32 0 17.673 14.326 32 32 32 17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32Z"
      fill="#636363"
    />
    <path
      d="M93.623 118.187v-.205c.022-2.172.25-3.9.682-5.185.432-1.285 1.046-2.326 1.842-3.122.796-.795 1.751-1.529 2.865-2.2a8.904 8.904 0 0 0 1.808-1.45 6.43 6.43 0 0 0 1.262-1.961c.319-.739.478-1.558.478-2.456 0-1.115-.261-2.081-.785-2.9a5.267 5.267 0 0 0-2.098-1.893c-.875-.444-1.847-.665-2.916-.665a6.53 6.53 0 0 0-2.695.58c-.864.386-1.586.995-2.166 1.825-.58.83-.916 1.916-1.007 3.257h-4.298c.091-1.933.591-3.587 1.501-4.963a9.038 9.038 0 0 1 3.633-3.155c1.513-.728 3.19-1.092 5.032-1.092 2.001 0 3.741.398 5.219 1.194 1.49.796 2.638 1.887 3.446 3.275.818 1.387 1.228 2.968 1.228 4.741 0 1.251-.194 2.383-.58 3.395a8.367 8.367 0 0 1-1.638 2.712 11.242 11.242 0 0 1-2.558 2.115c-1.001.625-1.802 1.285-2.405 1.978-.603.682-1.04 1.496-1.314 2.439-.272.944-.42 2.121-.443 3.531v.205h-4.093Zm2.183 10.097c-.842 0-1.564-.301-2.166-.904-.603-.603-.904-1.325-.904-2.166 0-.842.3-1.564.903-2.166.603-.603 1.325-.904 2.167-.904.841 0 1.563.301 2.166.904.603.602.904 1.324.904 2.166 0 .557-.142 1.069-.427 1.535a3.194 3.194 0 0 1-1.108 1.126 2.925 2.925 0 0 1-1.535.409Z"
      fill="#636363"
    />
  </svg>
)

export default UserNotFoundSvg
