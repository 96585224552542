import './WineList.css'
import moment from 'moment'
import StarSvg from "./StarSvg";
import { WineRecord } from "../../../../common/src/models/WineRecord";

type WineListProps = {
    wineList: WineRecord[] | undefined
}

const WineList = (props: WineListProps) => {
    return (
        <div>
            {
                props.wineList?.map((wine: WineRecord) => {
                    return (
                        <div className="wineItem" key={wine.key}>
                            <span className="title">
                                {wine.details?.winery} {wine.details?.year}
                            </span>

                            <div className="subtitleContainer">
                                <span className="subtitle1">
                                    {wine.details?.grape}
                                </span>
                                <span>|</span>
                                <span className="subtitle2">
                                    {wine.details?.location}
                                </span>
                            </div>

                            <span className="subtitle3">
                                {moment(wine.addedDate).format('MMMM DD, YYYY')}
                            </span>

                            <div className="rating-container">
                                <StarSvg />
                                <span>
                                    {wine.rating}
                                </span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default WineList